
import leafSvg from '@/assets/images/leaf.svg?raw'
import STEPS from '@/data/form.json'

export default {
    data () {
        return {
            leafSvg,
            STEPS,
        }
    },
    created () {
        // eslint-disable-next-line
        if (process.client) {
            // eslint-disable-next-line
            const isDesktop = window.innerWidth > 768

            if (isDesktop) {
                this.$store.dispatch('showModal', {
                    titleKey: 'HOME.WHAT_ARE_WE_DOING',
                    textKey: 'HOME.MAKING_GREEN_CHOICES',
                })
            }
        }
    },
    beforeDestroy () {
        this.$store.dispatch('hideModal')
    },
    methods: {
        toggleTooltip () {
            this.$store.dispatch('showModal', {
                titleKey: 'HOME.WHAT_ARE_WE_DOING',
                textKey: 'HOME.MAKING_GREEN_CHOICES',
            })
        },
    },
}
